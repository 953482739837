<template>
  <v-container class="mt-4" style="max-width: 800px">
    <v-form
      ref="form"
      class="white my-4 pa-8"
      v-model="isFormValid"
      v-on:submit.prevent="safeData()"
      :disabled="isFormDisabled()"
    >
      <v-row class="my-2">
        <v-col cols="12">
          <h2>PayPal</h2>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <v-select
            v-model="activeEnvironment"
            :items="paypalEnvironment"
            item-text="name"
            item-value="value"
            :label="$t('environment') + '*'"
            :rules="[rules.required]"
          >
          </v-select>
        </v-col>

        <template v-if="activeEnvironment === 'live'">
          <v-col cols="12">
            <h3>{{ $t("accessData") }} {{ $t("liveEnvironment") }}</h3>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-text-field
              label="Client-ID*"
              v-model="liveClientId"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-text-field
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              label="Token*"
              v-model="liveClientSecret"
              :rules="[rules.required]"
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-col>
        </template>

        <template v-if="activeEnvironment === 'test'">
          <v-col cols="12">
            <h3>{{ $t("accessData") }} {{ $t("sandboxEnvironment") }}</h3>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-text-field
              label="Client-ID*"
              v-model="sandboxClientId"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-text-field
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              label="Token*"
              v-model="sandboxClientSecret"
              :rules="[rules.required]"
              @click:append="show2 = !show2"
            ></v-text-field>
          </v-col>
        </template>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="d-flex align-end flex-column">
            <v-row>
              <v-btn color="grey" text @click="getData()">{{
                $t("cancel")
              }}</v-btn>
              <v-btn
                v-if="isFormValid"
                color="green"
                dark
                @click="safeData"
                :loading="fetchinData"
                :disabled="isFormDisabled()"
                >{{ $t("safe") }}</v-btn
              >
              <v-btn v-else disabled>{{ $t("safe") }}</v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)

export default {
  name: "CompanyApisPayPal",

  data() {
    return {
      show1: false,
      show2: false,
      isFormValid: false,
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      adminID: store.getters["auth/adminid"],
      data: [], // wrapper for fetched data,
      activeEnvironment: null,
      liveClientId: null,
      liveClientSecret: null,
      sandboxClientId: null,
      sandboxClientSecret: null,

      formDisabled: this.isFormDisabled(),

      paypalEnvironment: [
        {
          name: this.$t("sandboxEnvironment"),
          value: "test",
        },
        {
          name: this.$t("liveEnvironment"),
          value: "live",
        },
      ],

      rules: {
        required: (value) => !!value || this.$t("required"),
      },
      emailRules: [
        (v) => !!v || this.$t("required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("mailMustBeValid"),
      ],
    };
  },

  props: [],

  created: function () {
    /* starts immediatly when component was created */

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    isFormDisabled() {
      /* if (
        this.adminID === "1" ||
        this.adminID === "106" ||
        this.adminID === "836" ||
        this.adminID === "869"
      ) {
        return false;
      } */

      return false;
    },

    async safeData() {
      const data = {
        admin_id: this.adminID,
        activeEnvironment: this.activeEnvironment,
        liveClientId: this.liveClientId,
        liveClientSecret: this.liveClientSecret,
        sandboxClientId: this.sandboxClientId,
        sandboxClientSecret: this.sandboxClientSecret,
      };

      var dataArr = new URLSearchParams();
      dataArr.append("data", JSON.stringify(data)); // das komplette JSON übergeben

      this.fetchinData = true;
      let response = await this.putRequest(
        "companies/" + this.companyID + "/paypal",
        JSON.stringify(data),
        "json"
      );
      this.fetchinData = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async getData() {
      /* fetch data from api */
      this.fetchinData = true;
      let response = await this.getRequest(
        "companies/" + this.companyID + "/paypal"
      ); // await data from mixin
      this.fetchinData = false;
      this.data = response.data.data;

      this.activeEnvironment = this.data.activeEnvironment;
      this.liveClientId = this.data.liveClientId;
      this.liveClientSecret = this.data.liveClientSecret;
      this.sandboxClientId = this.data.sandboxClientId;
      this.sandboxClientSecret = this.data.sandboxClientSecret;
    },
  },

  computed: {
    //
  },

  /* all requests by mixin */
  mixins: [manageApi],
};
</script>
