<template>
  <v-container class="mt-6">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Wert</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in tableData" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.value }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)

export default {
  name: "CompanyData",

  components: {
    //
  },

  data() {
    return {
      tableData: [],
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      data: [], // wrapper for fetched data,
    };
  },

  created: function () {
    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    async getData() {
      /* fetch data from api */
      this.fetchinData = true;
      let response = await this.getRequest("companies/" + this.companyID); // await data from mixin
      this.fetchinData = false;
      this.data = response.data.data[0];

      // find every "null" in this.data array and replace it with ""

      for (var key in this.data) {
        if (this.data[key] === null) {
          this.data[key] = "";
        }
      }

      this.tableData = [
        {
          name: "Name",
          value: this.data.name,
        },
        {
          name: "Adresse",
          value: this.data.street + " " + this.data.streetNumber,
        },
        {
          name: "PLZ",
          value: this.data.zipCode,
        },
        {
          name: "Ort",
          value: this.data.town,
        },
        {
          name: "Land",
          value: this.data.country,
        },
        {
          name: "E-Mail",
          value: this.data.email,
        },
        {
          name: "Telefon",
          value: this.data.phone,
        },

        {
          name: "E-Mail Datenschutz",
          value: this.data.emailDataProtection
            ? this.data.emailDataProtection
            : this.data.email,
        },
        {
          name: "Rechtsform",
          value: this.data.gmbh,
        },
        {
          name: "Steuernummer",
          value: this.data.taxId,
        },
      ];
    },
  },

  mixins: [manageApi],
};
</script>
