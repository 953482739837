<template>
  <v-container class="mt-6">
    <v-form
      ref="form"
      class="white my-4 pa-8"
      v-model="isFormValid"
      v-on:submit.prevent="safeData()"
    >
      <h2 class="mb-4">{{ $t("bankData") }}</h2>

      <v-row class="my-2">
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            label="IBAN"
            v-model="iban"
            :rules="[rules.required]"
            @blur="openIbanRequest()"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            label="BIC"
            v-model="bic"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('accountHolder')"
            v-model="accountHolder"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('bankName')"
            v-model="bankName"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('creditorId')"
            v-model="creditorId"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="d-flex align-end flex-column">
            <v-row>
              <v-btn color="grey" text @click="getData()">{{
                $t("cancel")
              }}</v-btn>
              <v-btn
                v-if="isFormValid"
                color="green"
                dark
                @click="safeData"
                :loading="fetchinData"
                >{{ $t("safe") }}</v-btn
              >
              <v-btn v-else disabled>{{ $t("safe") }}</v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)

export default {
  name: "CompanyBankdata",

  data() {
    return {
      show1: false,
      show2: false,
      isFormValid: true,
      fetchinData: false,
      companyID: store.getters["auth/current_sid"],
      data: [],
      iban: null,
      bic: null,
      accountHolder: null,
      bankName: null,
      creditorId: null,
      rules: {
        required: (value) => !!value || this.$t("required"),
      },
    };
  },

  created: function () {
    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    async openIbanRequest() {
      //let result = "IBAN ist ungültig";
      if (this.iban) {
        return new Promise((resolve) => {
          fetch(
            `https://openiban.com/validate/${this.iban}?getBIC=true&validateBankCode=true`
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.valid) {
                this.bic = data.bankData.bic;
                this.bankName = data.bankData.name;
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              // when error, return true to keep form valid if api is down or not reachable
              console.error("Error:", error);
              resolve(true);
            });
        });
      }
    },

    async safeData() {
      const data = {
        iban: this.iban,
        bic: this.bic,
        accountHolder: this.accountHolder,
        bankName: this.bankName,
        creditorId: this.creditorId,
      };

      this.fetchinData = true;
      let response = await this.putRequest(
        `companies/${this.companyID}/bankAccounts`,
        JSON.stringify(data),
        "json"
      ); // await data from mixin
      this.fetchinData = false;

      /* mutate data */
      this.data = response.data;

      /* CALL SNACKBAR */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/bankAccounts`
      );
      this.fetchinData = false;

      if (response.status === 200) {
        this.data = response.data.data;
        this.iban = response.data.data.iban;
        this.bic = response.data.data.bic;
        this.accountHolder = response.data.data.accountHolder;
        this.bankName = response.data.data.bankName;
        this.creditorId = response.data.data.creditorId;
      }
    },
  },
  /* all requests by mixin */
  mixins: [manageApi],
};
</script>
