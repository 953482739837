<template>
  <v-form ref="form" v-model="isFormValid" v-on:submit.prevent="safeData()">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="permissions"
            :items="permissionItems"
            item-text="name"
            item-value="value"
            attach
            chips
            :label="$t('permissions')"
            multiple
            :hint="$t('employeePermissionInfo')"
            persistent-hint
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-select
            v-model="sex"
            :items="genderItems"
            item-text="name"
            item-value="value"
            :label="$t('gender')"
            required
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('firstName')"
            v-model="firstname"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('lastName')"
            v-model="lastname"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field :label="$t('phone')" v-model="phone"></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            :label="$t('email')"
            v-model="itemData.mail"
            disabled
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <div class="d-flex align-end flex-column">
            <v-row>
              <v-btn color="grey" text @click="$emit('closeDialog')">{{
                $t("cancel")
              }}</v-btn>
              <v-btn
                v-if="isFormValid"
                color="green"
                dark
                @click="safeData"
                :loading="fetchinData"
                >{{ $t("safe") }}</v-btn
              >
              <v-btn v-else disabled>{{ $t("safe") }}</v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi";
export default {
  name: "EmployeeDetail",

  data() {
    return {
      pageTitle: this.itemData.firstname + " " + this.itemData.lastname,
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      componentKey: 0,
      firstname: this.itemData.firstname,
      lastname: this.itemData.lastname,
      sex: this.itemData.sex,
      phone: this.itemData.phone,
      permissions: this.permissionsArray,
      isFormValid: false,
      genderItems: [
        {
          name: this.$t("female"),
          value: "female",
        },
        {
          name: this.$t("male"),
          value: "male",
        },
        {
          name: this.$t("diverse"),
          value: "diverse",
        },
      ],

      permissionItems: [
        {
          name: this.$t("jmp_fitness"),
          value: "2",
        },
        {
          name: this.$t("leads"),
          value: "4",
        },
        {
          name: this.$t("campaigns"),
          value: "5",
        },
        {
          name: this.$t("shop"),
          value: "10",
        },
        {
          name: "Member",
          value: "12",
        },
        {
          name: this.$t("company"),
          value: "6",
        },
        {
          name: this.$t("gdpr"),
          value: "7",
        },
        {
          name: this.$t("settings"),
          value: "9",
        },
      ],
      rules: {
        required: (value) => !!value || this.$t("required"),
      },
    };
  },

  props: ["id", "itemData"],

  created: function () {
    this.permissions = this.permissionsArray;
  },

  methods: {
    async safeData() {
      let permissionsString = "";

      this.permissions.forEach((item) => {
        if (permissionsString === "") {
          permissionsString += item;
        } else {
          permissionsString += "," + item;
        }
      });

      var dataArr = new URLSearchParams();
      dataArr.append("sex", this.sex);
      dataArr.append("firstname", this.firstname);
      dataArr.append("lastname", this.lastname);
      dataArr.append("phone", this.phone);
      dataArr.append("mail", this.itemData.mail);
      dataArr.append("permissions", permissionsString);
      this.fetchinData = true;
      let response = await this.patchRequest("admins/" + this.id, dataArr); // await data from mixin
      this.fetchinData = false;

      /* mutate itemData */
      this.itemData.firstname = response.data.data.firstname;
      this.itemData.lastname = response.data.data.lastname;
      this.itemData.mail = response.data.data.mail;
      this.itemData.phone = response.data.data.phone;
      this.itemData.sex = response.data.data.sex;
      this.itemData.permissions = response.data.data.permissions;

      /* CALL SNACKBAR */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;

      this.$emit("closeDialog");
    },
  },

  computed: {
    permissionsArray: {
      get() {
        const str = this.itemData.permissions;
        return str.split(",");
      },
      set() {
        //
      },
    },
  },

  /* all requests by mixin */
  mixins: [manageApi],
};
</script>
