<template>
  <v-container class="mt-6 pa-8 white">
    <h2 class="mb-4">{{ $t("contractForOrderProcessing") }}</h2>

    <v-row>
      <v-col cols="12">
        <v-btn
          href="Auftragsverarbeitungs-Vertrag nach DSGVO - FACEFORCE.pdf"
          target="_blank"
          color="green"
          class="white--text"
        >
          Download
          <v-icon right dark>mdi-file-pdf-box</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
export default {
  name: "CompanyGdpr",
  data() {
    return {
      companyID: store.getters["auth/current_sid"],
    };
  },
};
</script>
