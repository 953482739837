<template>
  <v-dialog v-model="dialogState" max-width="500">
    <v-card>
      <v-toolbar dark color="red" elevation="0">
        <v-btn icon dark @click="$emit('reject')"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <v-toolbar-title>{{ headline }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="py-4">
        {{ message }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" text @click="$emit('reject')">{{
          $t("Cancel")
        }}</v-btn>
        <v-btn color="red" text @click="$emit('accept')">{{
          $t("Accept")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* <ConfirmationDialog 
    :dialog="deleteDialog" 
    headline="deleteDialog" 
    message="deleteDialog"
    @reject="deleteDialog = false"
    @accept="deleteItem"
    /> */
export default {
  name: "ConfirmationDialog",

  props: ["dialog", "headline", "message"],

  computed: {
    dialogState: {
      get() {
        return this.dialog;
      },
      set() {},
    },
  },
};
</script>
