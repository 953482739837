<template>
  <v-container class="mt-6">
    <DataTable
      :headers="headers"
      :items="data"
      :preloader="fetchingData"
      :edit="true"
      :trash="adminsOnly()"
      @tableEvent="emittedTableEvent"
    ></DataTable>

    <v-container class="mt-6">
      <v-row>
        <v-col cols="12">
          <div class="d-flex align-end flex-column">
            <v-row>
              <v-btn large color="green" dark @click="addItem">
                {{ $t("addEmployee") }}
                <v-icon right dark>mdi-plus</v-icon>
              </v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="dialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title>{{ $t("employee") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <EmployeeDetail
              ref="employee_detail"
              :itemData="itemData"
              :id="itemId"
              :key="componentKey"
              @closeDialog="dialog = false"
            ></EmployeeDetail>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogNewEmployee"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="dialogNewEmployee = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title>{{ $t("employee") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <EmployeeNew
              ref="employee_new"
              :itemData="itemData"
              @closeDialog="dialogNewEmployee = false"
              @newItemAdded="newItemAdded"
              :key="componentKey"
            ></EmployeeNew>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ConfirmationDialog
      :dialog="deleteDialog"
      :headline="$t('areYouSure')"
      :message="$t('deleteItemConfirmationText')"
      @reject="deleteDialog = false"
      @accept="deleteItem"
    />
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import DataTable from "@/components/ui/DataTable";
import EmployeeDetail from "@/components/company/EmployeeDetail";
import EmployeeNew from "@/components/company/EmployeeNew";
import ConfirmationDialog from "@/components/ui/ConfirmationDialog";
export default {
  name: "CompanyEmployee",

  components: {
    DataTable,
    EmployeeDetail,
    EmployeeNew,
    ConfirmationDialog,
  },

  data() {
    return {
      fetchingData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      adminID: store.getters["auth/adminid"],
      adminType: store.getters["auth/adminType"],
      data: [], // wrapper for fetched data
      headers: [],
      dialog: false,
      dialogNewEmployee: false,
      deleteDialog: false,
      componentKey: 0,
      itemData: [],
      itemId: null,
      itemIndex: null,
    };
  },

  props: [],

  created: function () {
    /* starts immediatly when component was created */

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    adminsOnly() {
      if (this.adminType === "admin" || this.adminType === "faceforce") {
        return true;
      }
    },

    newItemAdded() {
      this.dialogNewEmployee = false;
      this.getData();
    },

    addItem() {
      this.componentKey += 1;
      this.dialogNewEmployee = true;
    },

    async deleteItem() {
      this.fetchingData = true;
      let response = await this.deleteRequest("admins/" + this.itemId); // await data from mixin
      this.fetchingData = false;

      if (response.status === 200) {
        this.data.splice(this.itemIndex, 1);

        /* CALL SNACKBAR */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t("messageDeleted");
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        this.deleteDialog = false;
      }
    },

    emittedTableEvent(value) {
      if (value.action === "editItem") {
        this.itemId = value.itemId;

        /* finde the real data in array
        vorher bin ich über INDEX gegangen, was sich aber als falsch herausgestellt hatte, da der INDEX auf die aktuelle Page der Tabelle bezogen war. */
        this.data.forEach((item) => {
          if (item.id === this.itemId) {
            this.itemData = item; // the data of that item
          }
        });

        this.dialog = true;
        this.componentKey += 1; // forces the component (here LeadDetail) to re-render -> https://michaelnthiessen.com/force-re-render/
      } else if (value.action === "deleteItem") {
        if (this.adminID === value.itemId) {
          alert("Geht nicht");
        } else {
          this.itemId = value.itemId;
          this.itemIndex = value.itemIndex;
          this.deleteDialog = true;
        }
      }
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(
        "companies/" + this.companyID + "/employees"
      );
      this.fetchingData = false;
      this.data = response.data.data;

      this.headers = [
        { text: this.$t("fullname"), value: "adminFullname" },
        { text: this.$t("email"), value: "mail" },
        { text: this.$t("phone"), value: "phone" },
        { text: "", value: "actions", sortable: false },
      ];
    },
  },

  mixins: [manageApi],
};
</script>
