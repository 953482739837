<template>
  <v-container class="mt-6">
    <v-form
      v-if="!fetchingData"
      ref="form"
      class="white my-4 pa-8"
      v-model="isFormValid"
      v-on:submit.prevent="safeData()"
    >
      <h2 class="mb-4">{{ $t("companyTerms") }}</h2>

      <v-row v-if="shopTermsAndConditionsHtml.length > 0">
        <v-col cols="12">
          <tiptap-vuetify
            v-model="shopTermsAndConditionsHtml"
            :extensions="extensions"
          />
        </v-col>

        <v-col cols="12">
          <div class="d-flex align-end flex-column">
            <v-row>
              <v-btn color="red" text @click="deleteData()">{{
                $t("delete")
              }}</v-btn>
              <v-btn
                v-if="isFormValid"
                color="green"
                dark
                @click="safeData"
                :loading="fetchingData"
                >{{ $t("safe") }}</v-btn
              >
              <v-btn v-else disabled>{{ $t("safe") }}</v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12">
          <v-alert type="warning">
            {{ $t("noData") }} Es werden die Standarddaten verwendet.
          </v-alert>
          <v-btn color="green" class="white--text" @click="createNew()">{{
            $t("createNewTerms")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import store from "@/store";
import apiRequest from "@/mixins/apiRequest"; // import mixin (for api requests)

export default {
  name: "CompanyTerms",

  components: { TiptapVuetify },

  data() {
    return {
      isFormValid: false,
      fetchingData: false, // should be true when fetching data
      pending: false, // should be true when sending data
      companyID: store.getters["auth/current_sid"],
      lang: store.getters["auth/lang"],
      data: [], // wrapper for fetched data,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      // starting editor's content
      shopTermsAndConditionsHtml: null,
    };
  },

  props: [],

  created: function () {
    /* starts immediatly when component was created */

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    async deleteData() {
      const userChoice = await this.$dialog.confirm({
        text: this.$t("deleteItemConfirmationText"),
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        // do it

        this.fetchingData = true;
        await this.deleteRequest(
          "companies/" +
            this.companyID +
            "/pay/templates/shopTermsAndConditionsHtml"
        );
        this.fetchingData = false;
        /* CALL SNACKBAR */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t("messageDeleted");
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
        this.getData();
      }
    },

    async safeData() {
      var dataArr = new URLSearchParams();
      dataArr.append("name", "shopTermsAndConditionsHtml");
      dataArr.append("language", this.lang);
      dataArr.append("value", this.shopTermsAndConditionsHtml);

      /* "companies/" +
          this.companyID +
          "/pay/templates/shopTermsAndConditionsHtml" */
      this.fetchingData = true;
      await this.patchRequest(
        "companies/" +
          this.companyID +
          "/pay/templates/shopTermsAndConditionsHtml",
        dataArr
      );
      this.fetchingData = false;

      /* CALL SNACKBAR */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },

    async getData() {
      this.fetchingData = true;
      // /companies/{companyID}/pay/templates/{templateName}
      let response = await this.getPayRequest(
        "companies/" +
          this.companyID +
          "/pay/templates/shopTermsAndConditionsHtml"
      );
      this.fetchingData = false;
      console.log(response.data.data);
      this.shopTermsAndConditionsHtml = response.data.data;
    },

    async createNew() {
      this.pending = true;
      let response = await this.postRequest(
        "companies/" +
          this.companyID +
          "/pay/templates/shopTermsAndConditionsHtml"
      );
      this.pending = false;
      if (response.status == 200 || response.status == 201) {
        this.getData();
      }
    },
  },

  mixins: [apiRequest],
};
</script>
